"use strict";

// Connecting vendors (plugins)
import "./_vendor";

// Functions
import { mobileCheck, modals, burger, lang } from "./functions/";
import { formValidation } from "./components/";

// Components
// import { formValidation } from "./components/";

window.addEventListener("DOMContentLoaded", () => {
  mobileCheck();
  burger();
  lang();
  modals();
  formValidation();
});
