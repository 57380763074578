// Functions
import { validateForms } from "../functions/validate-forms";

export const formValidation = () => {
  //Polish
  const formBlock = document.querySelector(".form-block-pl");
  if (formBlock) {
    validateForms(
      ".form-block-pl",
      [
        {
          ruleSelector: ".input__name",
          rules: [
            {
              rule: "required",
              errorMessage: "Name is required",
            },
          ],
        },
        {
          ruleSelector: ".input__tell",
          rules: [
            {
              rule: "tel",
              value: 2,
              rule: "minLength",
              errorMessage: "Phone is not valid",
            },
            {
              rule: "required",
              errorMessage: "Phone is required",
            },
          ],
        },
      ],
      null,
      null,
      null,
      [
        {
          key: "Name is required",
          dict: {
            pl: "Wpisz swoje imię",
          },
        },
        {
          key: "Phone is required",
          dict: {
            pl: "Wprowadź swój numer telefonu",
          },
        },
      ],
      "pl"
    );
  }

  //German
  const formBlockEn = document.querySelector(".form-block-en");
  if (formBlockEn) {
    validateForms(
      ".form-block-en",
      [
        {
          ruleSelector: ".form-block__input-en",
          rules: [
            {
              rule: "required",
              errorMessage: "Name is required",
            },
          ],
        },
        {
          ruleSelector: ".form-block__input-en-1",
          rules: [
            {
              rule: "tel",
              value: 2,
              rule: "minLength",
              errorMessage: "Phone is not valid",
            },
            {
              rule: "required",
              errorMessage: "Phone is required",
            },
          ],
        },
      ],
      null,
      null,
      null,
      [
        {
          key: "Name is required",
          dict: {
            de: "Gib deinen Namen ein",
          },
        },
        {
          key: "Phone is required",
          dict: {
            de: "Geben Sie Ihr Telefon ein",
          },
        },
      ],
      "de"
    );
  }

  //Polish
  const modalBlockRu = document.querySelector(".modal-call__form-ru");
  if (modalBlockRu) {
    validateForms(
      ".modal-call__form-ru",
      [
        {
          ruleSelector: ".modal-call-name-ru",
          rules: [
            {
              rule: "required",
              errorMessage: "Name is required",
            },
          ],
        },
        {
          ruleSelector: ".modal-call-tel-ru",
          rules: [
            {
              rule: "tel",
              value: 2,
              rule: "minLength",
              errorMessage: "Phone is not valid",
            },
            {
              rule: "required",
              errorMessage: "Phone is required",
            },
          ],
        },
      ],
      null,
      null,
      null,
      [
        {
          key: "Name is required",
          dict: {
            pl: "Wpisz swoje imię",
          },
        },
        {
          key: "Phone is required",
          dict: {
            pl: "Wprowadź swój numer telefonu",
          },
        },
      ],
      "pl"
    );
  }

  //German
  const modalBlockEn = document.querySelector(".modal-call__form-en");
  if (modalBlockEn) {
    validateForms(
      ".modal-call__form-en",
      [
        {
          ruleSelector: ".modal-call-name-en",
          rules: [
            {
              rule: "required",
              errorMessage: "Name is required",
            },
          ],
        },
        {
          ruleSelector: ".modal-call-tel-en",
          rules: [
            {
              rule: "tel",
              value: 2,
              rule: "minLength",
              errorMessage: "Phone is not valid",
            },
            {
              rule: "required",
              errorMessage: "Phone is required",
            },
          ],
        },
      ],
      null,
      null,
      null,
      [
        {
          key: "Name is required",
          dict: {
            de: "Gib deinen Namen ein",
          },
        },
        {
          key: "Phone is required",
          dict: {
            de: "Geben Sie Ihr Telefon ein",
          },
        },
      ],
      "de"
    );
  }

  //Polish
  const contactsBlockPl = document.querySelector(".contacts-form-pl");
  if (contactsBlockPl) {
    validateForms(
      ".contacts-form-pl",
      [
        {
          ruleSelector: ".contacts-form__name-pl",
          rules: [
            {
              rule: "required",
              errorMessage: "Name is required",
            },
          ],
        },
        {
          ruleSelector: ".contacts-form__tel-pl",
          rules: [
            {
              rule: "tel",
              value: 2,
              rule: "minLength",
              errorMessage: "Phone is not valid",
            },
            {
              rule: "required",
              errorMessage: "Phone is required",
            },
          ],
        },
      ],
      null,
      null,
      null,
      [
        {
          key: "Name is required",
          dict: {
            pl: "Wpisz swoje imię",
          },
        },
        {
          key: "Phone is required",
          dict: {
            pl: "Wprowadź swój numer telefonu",
          },
        },
      ],
      "pl"
    );
  }

  //German
  const contactsBlockDe = document.querySelector(".contacts-form-de");
  if (contactsBlockDe) {
    validateForms(
      ".contacts-form-de",
      [
        {
          ruleSelector: ".contacts-form__name-de",
          rules: [
            {
              rule: "required",
              errorMessage: "Name is required",
            },
          ],
        },
        {
          ruleSelector: ".contacts-form__tel-de",
          rules: [
            {
              rule: "tel",
              value: 2,
              rule: "minLength",
              errorMessage: "Phone is not valid",
            },
            {
              rule: "required",
              errorMessage: "Phone is required",
            },
          ],
        },
      ],
      null,
      null,
      null,
      [
        {
          key: "Name is required",
          dict: {
            de: "Gib deinen Namen ein",
          },
        },
        {
          key: "Phone is required",
          dict: {
            de: "Geben Sie Ihr Telefon ein",
          },
        },
      ],
      "de"
    );
  }
};
