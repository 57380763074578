export const fixedHeader = () => {
  function trackScroll() {
    let scrolled = window.pageYOffset || document.documentElement.scrollTop;
    const header = document.querySelector(".header");

    if (scrolled >= 20) {
      header.classList.add("fixed");
    } else {
      header.classList.remove("fixed");
    }
  }
  window.addEventListener("scroll", trackScroll);
};
